































































































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
@Component({
  name: 'Kubernetes',
})
export default class Kubernetes extends VueBase {}
